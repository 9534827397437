import axios from 'axios';
import config from './properties.json';

const baseURL = config.baseURL;

const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use((req) => {
  if (!req.headers.Authorization) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('token'))}`;
  }
  return req;
});
api.interceptors.response.use((res) => res, (err) => {
  if (err.toJSON().message === 'Network Error') {
    console.log("Network Error!");
  }
  throw err;
});

window.api = api;

export default api;
