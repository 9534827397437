import React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { MdOutlineError } from 'react-icons/md';
import { Pane } from 'evergreen-ui';

const ConfirmationPrompt = ({type}) => {
    
    // var description = "";
    const data = ["If you have an account with us, you will receive an email with link to reset your password.",
        "Link has expired or wrong request id detected",
        "Your Password has been reset!",
        "If you have an account with us, you will receive an email with link to delete your account.",
        "Your Account Is now"]
    
    return (
        <div>
            { type === "1" ? (
                <>
                    <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                        <BsCheckCircleFill size={40} color="green"/>
                    </Pane>
                    <p className="card-title text-center mt-4 mb-4">{data[0]}</p>
                </>
            ) : null}
            { type === "2" ? (
                <>
                    <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                        <MdOutlineError size={40} color="red"/>
                    </Pane>
                    <p className="card-title text-center mt-4 mb-4">{data[1]}</p>
                </>
            ) : null}
            { type === "3" ? (
              <>
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                      <BsCheckCircleFill size={40} color="green"/>
                  </Pane>
                  <p className="card-title text-center mt-4 mb-4">{data[2]}</p>
              </>
            ): null}
            { type === "4" ? (
              <>
                  <Pane display="flex" alignItems="center" justifyContent="center" height={50}>
                      <BsCheckCircleFill size={40} color="green"/>
                  </Pane>
                  <p className="card-title text-center mt-4 mb-4">{data[3]}</p>
              </>
            ): null}
        </div>
    );
};

export default ConfirmationPrompt;