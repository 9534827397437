import { Routes,
  Route,
  BrowserRouter } from "react-router-dom";
import './App.css';
import ResetPassword from './pages/ResetPassword.js';
import ResetWithUID from './pages/ResetWithUID';
import ArchiveAccount from "./pages/ArchiveAccount";
import { ResetPasswordContextProvider } from './context/ResetPasswordContext';
import ArchiveWithUID from './pages/ArchiveWithUID';

function App() {

  return (
    <>
      <BrowserRouter>
        <ResetPasswordContextProvider>
        <Routes>
          <Route path='/' element={<ResetPassword />} />
          <Route path='/account/delete' element={<ArchiveAccount />} />
          <Route path='/:uid' element={<ResetWithUID />} />
          <Route path='/account/delete/confirm/:uid' element={<ArchiveWithUID />} />
        </Routes>
        </ResetPasswordContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
