import '../App.css';
import '../dist/css/tabler.min.css';
import '../dist/css/tabler-flags.min.css';
import '../dist/css/tabler-payments.min.css';
import '../dist/css/tabler-vendors.min.css';
import '../dist/css/demo.min.css';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import logo2 from "../dist/img/baddy/baddy.png";
import { useResetPasswordContext } from '../context/ResetPasswordContext';
import { Spinner, Pane } from 'evergreen-ui';
import ConfirmationPrompt from '../components/ConfirmationPrompt';

const ArchiveWithUID = () => {

  let [loading, setLoading] = useState(true);
  let [name, setName] = useState("");
  let [invalidLink, setInvalidLink] = useState(false);
  const params = useParams();
  const {executeAccountArchive} = useResetPasswordContext();

  useEffect(() => {

    async function validate() {
      const res = await executeAccountArchive(params.uid);
      if (res == null) {
        setInvalidLink(true);
      } else {
        setName(res.data.firstName + ' ' + res.data.lastName);
        setLoading(false);
      }
    }
    validate();

  }, [params.uid, executeAccountArchive]);

  return (
    <>
        <div  className="border-top-wide border-primary d-flex flex-column">
            <div className="page page-center">
                <div className="container-tight py-4">
                    <div className="text-center mb-4">
                    </div>
                    <form className="card card-md" action="." method="get">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4"><img src={logo2} width ="70" height="70" alt="" />Delete Account</h2>
                                { invalidLink ? (
                                  <>
                                    <ConfirmationPrompt type="2" />
                                    <div className="form-footer">
                                      <a href="/account/delete" className="btn btn-primary w-100">
                                          Create Account Delete Request
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {loading ? (
                                      <>
                                        <Pane display="flex" alignItems="center" justifyContent="center" height={100}>
                                          <Spinner />
                                        </Pane>
                                      </>) : (
                                        <>
                                          <p className="text">Hi {name}</p>
                                          <p className="text mb-4">Your account with us has been deleted.</p>
                                        </>
                                    ) }
                                  </>
                                )}
                        </div>
                    </form>
                </div>
            </div>
            <script src="./dist/js/tabler.min.js"></script>
            <script src="./dist/js/demo.min.js"></script>
        </div>
    </>
  );
}

export default ArchiveWithUID;