import '../App.css';
import '../dist/css/tabler.min.css';
import '../dist/css/tabler-flags.min.css';
import '../dist/css/tabler-payments.min.css';
import '../dist/css/tabler-vendors.min.css';
import '../dist/css/demo.min.css';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import logo2 from "../dist/img/baddy/baddy.png";
import { useResetPasswordContext } from '../context/ResetPasswordContext';
import { Spinner, Pane } from 'evergreen-ui';
import ConfirmationPrompt from '../components/ConfirmationPrompt';

const ResetWithUID = () => {

  let [loading, setLoading] = useState(false);
  let [completedReset, setCompletedReset] = useState(false);
  let [name, setName] = useState("");
  let [resetId, setResetId] = useState(0);
  let [pass1, setPass1] = useState("");
  let [pass2, setPass2] = useState("");
  let [invalidLink, setInvalidLink] = useState(false);
  let [passwordMatchLock, setPasswordMatchLock] = useState(true);
  const params = useParams();
  const {validateAndGetName, resetPassword} = useResetPasswordContext();

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  useEffect(() => {

    async function validate() {
      const res = await validateAndGetName(params.uid);
      if (res == null) {
        setInvalidLink(true);
      } else {
        setName(res.data.firstName + ' ' + res.data.lastName);
        setResetId(res.data.id);
        console.log(res.data.accountId);
      }
    }
    validate();

  }, [params.uid, validateAndGetName]);

  useEffect(() => {

    if ((pass1 === pass2) && pass1 !== "" && pass2 !== "") {
      setPasswordMatchLock(false);
    }

  }, [pass1, pass2]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setPasswordMatchLock(true);

    if (pass1 !== pass2) {
      alert("Passwords do not match. Please Try Again!");
      setLoading(false);
    } else if (pass1 === "" || pass2 ==="") {
      alert("One or more of passwords is empty! Please Try Again!");
      setLoading(false);
    } else {
      console.log("Passwords Match. Processing!");
      try {
        await resetPassword(resetId, pass1);
      } catch (err) {
        
        console.log(err);

      } finally {
          await sleep(1000)
          setLoading(false);
          setPasswordMatchLock(false);
          setCompletedReset(true);
      }
    }
  }

  return (
    <>
        <div  className="border-top-wide border-primary d-flex flex-column">
            <div className="page page-center">
                <div className="container-tight py-4">
                    <div className="text-center mb-4">
                    </div>
                    <form className="card card-md" action="." method="get" onSubmit={handleSubmit}>
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4"><img src={logo2} width ="70" height="70" alt="" />Forgot password</h2>

                            { completedReset ? (
                              <>
                                <ConfirmationPrompt type="3" />
                              </>
                            ) : (
                              <>
                                { invalidLink ? (
                                  <>
                                    <ConfirmationPrompt type="2" />
                                    <div className="form-footer">
                                      <a href="/" className="btn btn-primary w-100">
                                          Create New Password Reset
                                      </a>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <p className="text">Hi {name}</p>
                                    <p className="text mb-4">Enter your new password for your account.</p>
                                    <div className="mb-3">
                                        <label className="form-label">New Password</label>
                                        <input type="password" className="form-control" placeholder="Enter Password" autoComplete='new-password' value={pass1} onChange={e => setPass1(e.target.value)} disabled={loading}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Confirm Password</label>
                                        <input type="password" className="form-control" placeholder="Re-Enter Password" autoComplete='new-password' value={pass2} onChange={e => setPass2(e.target.value)} disabled={loading}/>
                                    </div>
                                    {loading ? (
                                      <>
                                        <Pane display="flex" alignItems="center" justifyContent="center" height={100}>
                                          <Spinner />
                                        </Pane>
                                      </>) : null }
                                    <div className="form-footer">
                                        <button type="submit" className="btn btn-primary w-100" disabled={passwordMatchLock}>
                                            Reset Password
                                        </button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                            
                        </div>
                    </form>
                </div>
            </div>
            <script src="./dist/js/tabler.min.js"></script>
            <script src="./dist/js/demo.min.js"></script>
        </div>
    </>
  );
}

export default ResetWithUID;