import '../App.css';
import '../dist/css/tabler.min.css';
import '../dist/css/tabler-flags.min.css';
import '../dist/css/tabler-payments.min.css';
import '../dist/css/tabler-vendors.min.css';
import '../dist/css/demo.min.css';
import { useEffect, useState } from 'react';
import logo2 from "../dist/img/baddy/baddy.png";
import { useResetPasswordContext } from '../context/ResetPasswordContext';
import { Spinner, Pane } from 'evergreen-ui';
import ConfirmationPrompt from '../components/ConfirmationPrompt';

const ResetPassword = () => {

  let [loading, setLoading] = useState(false);
  let [completedReset, setCompletedReset] = useState(false);

  const {generateNewResetRequest} = useResetPasswordContext();

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  useEffect(() => {
      // console.log(params.uid);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
        await generateNewResetRequest(event.target[0].value)
    } catch (err) {
      
      console.log(err);

    } finally {
        await sleep(1000)
        setLoading(false);
        setCompletedReset(true);
    }
  }

  return (
    <>
        <div  className="border-top-wide border-primary d-flex flex-column">
            <div className="page page-center">          
                <div className="container-tight py-4">
                    <form className="card card-md" action="." method="get" onSubmit={handleSubmit}>
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4"><img src={logo2} width ="70" height="70" alt="" />Forgot password</h2>
                            { completedReset ? (
                              <>
                                <ConfirmationPrompt type="1" />
                              </>
                            ) : (
                              <>
                                <p className="text-muted mb-4">Enter your email address and your password will be reset and emailed to you.</p>
                                <div className="mb-3">
                                    <label className="form-label">Email address</label>
                                    <input type="email" className="form-control" placeholder="Enter email" disabled={loading}/>
                                </div>
                                {loading ? (
                                <>
                                  <Pane display="flex" alignItems="center" justifyContent="center" height={100}>
                                    <Spinner />
                                  </Pane>
                                </>) : null }
                                
                                <div className="form-footer">
                                    <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><rect x="3" y="5" width="18" height="14" rx="2" /><polyline points="3 7 12 13 21 7" /></svg>
                                        Reset My Password
                                    </button>
                                </div>
                              </>
                            )}
                            
                        </div>
                    </form>
                </div>
            </div>
            <script src="./dist/js/tabler.min.js"></script>
            <script src="./dist/js/demo.min.js"></script>
        </div>
    </>
  );
}

export default ResetPassword;