import React, {createContext, useContext} from 'react';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router';
import api from '../api';

export const ResetPasswordContext = createContext();

export function ResetPasswordContextProvider ({ children }) {
  const validateAndGetName = async (uid) => {
    // Given a guid, validate it is in DB and get a name back
    return await api.get('/api/v1/auth/passwordreset/' + uid)
        .catch(function (error) {
          console.log(error);
          return null;
        });

  };

  const generateNewResetRequest = async (email) => {
    const res = await api.get('/api/v1/auth/' + email + '/forgetpassword');
    return res.data;
  };

  const resetPassword = async (resetId, newPassword) => {
     const res = await api.post('/api/v1/auth/passwordreset', { "passwordResetId": resetId, "newPassword": newPassword });
    return res.data;
  };

  const generateArchiveAccountRequest = async (email) => {
    const res = await api.get('/api/v1/auth/account/archive/' + email);
    return res.data;
  }

  const executeAccountArchive = async (id) => {
    // Given a guid, validate it is in DB and get a name back
    return await api.get('/api/v1/auth/account/archive/confirm/' + id)
      .catch(function (error) {
        console.log(error);
        return null;
      });

  };

  return (
    <ResetPasswordContext.Provider value={{
      validateAndGetName, generateNewResetRequest, resetPassword, generateArchiveAccountRequest, executeAccountArchive
    }}
    >
      {children}
    </ResetPasswordContext.Provider>
  );
}

ResetPasswordContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useResetPasswordContext = () => useContext(ResetPasswordContext);
